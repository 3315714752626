import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import styled      from '@emotion/styled';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderImage       from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import { Button }        from '@interness/web-core/src/components/elements/Button/Button';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import { findMedia }     from '@interness/web-core/src/components/_helpers';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

import HeaderImg from '../components/HeaderImg';

const dispalyItems = [
  {
    title: 'Praxis',
    descr: 'Was in den 1980er Jahren mit zwei Einzelpraxen in Gevelsberg begann, hat sich nach der Zusammenlegung und Gründung einer Gemeinschaftspraxis 1992 in Schwelm bewährt.',
    image: 'team-1',
    path: '/praxis'
  },
  {
    title: 'Beschwerdebilder',
    descr: 'Bei folgenden gesundheitlichen Problemen und Beschwerdebildern können wir Ihnen, nach individueller Beratung, bewährte Therapie-Konzepte anbieten:',
    image: 'rueckenbeschwerden',
    path: '/beschwerdebilder/rueckenbeschwerden'
  },
  {
    title: 'Diagnoseverfahren',
    descr: 'Neben der klinischen Diagnostik (Blut- und Stuhl-Untersuchungen) liefert uns die Iris- (Augen-) Diagnose wichtige Hinweise auf genetische / familiäre und individuelle Schwachstellen.',
    image: 'iris-diagnostik',
    path: '/diagnoseverfahren/irisdiagnose'
  },
  {
    title: 'Therapien',
    descr: 'Unsere inzwischen über 30-jährige therapeutische Erfahrung setzen wir in individuellen Therapiekonzepten um und begleiten Sie mit individuellen Maßnahmen.',
    image: 'infusionen',
    path: '/therapien/vitamin-c-infusion'
  }
];

const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DisplayItem = styled.div`
  width: 22%;
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 960px) {
    width: 48%;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
  > div {
    padding: 10px 0 0 0;
    margin-bottom: 20px;
  }
  img {
    margin-bottom: 0;
  }
  h4 {
    margin: 10px 0 5px 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
  }
  p {
    text-align: justify;
    font-size: 0.9rem;
  }
  a {
    position: absolute;
    bottom: -42px;
  }
`;

function IndexPage(props) {
  return (
    <>
      <HeaderImage>
        <HeaderImg id="startseite"/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Herzlich Willkommen'}>Naturheilpraxis Deipenbrock & Mertler</Heading>
        <section style={{ maxWidth: '960px', margin: 'auto', textAlign: 'justify' }}>
          <p>Herzlich Willkommen in unserer Heilpraktiker-Gemeinschaftspraxis, die seit 1992 ihre Türen für Ihre Gesundheit geöffnet hat! Wir freuen uns, dass Sie den Weg zu uns gefunden haben. Unsere Praxis liegt zentral am Bürgerplatz, inmitten der Fußgängerzone, und bietet Ihnen damit eine bequeme Erreichbarkeit. Parkmöglichkeiten stehen Ihnen am Neumarkt zur Verfügung, sodass Sie sich ganz auf Ihre Gesundheit konzentrieren können.</p>
          <p>Als Lehrbeauftragte für Naturheilverfahren des Freien Verbandes Deutscher Heilpraktiker e.V. (FVDH) legen wir großen Wert auf eine ganzheitliche und individuelle Behandlung. Bei uns stehen Sie im Mittelpunkt. Unser erfahrenes Team widmet sich einfühlsam Ihren Beschwerdebildern, sei es Herz-Kreislauf-Durchblutungs-Störungen, Probleme des Bewegungsapparates wie Wirbelsäule, Gelenke, Arthrose und Rheuma, Immun- oder Abwehr-Schwäche, chronische Darm- und Hauterkrankungen oder Allergien und Unverträglichkeiten.</p>
          <p>Um eine fundierte Diagnose zu stellen, setzen wir verschiedene Verfahren ein. Dazu gehören die Iris- / Augendiagnose, klinische Blut- und Stuhl-Labortests sowie Bioresonanz-Testungen auf Umweltbelastungen und Allergene, sei es in Lebensmitteln oder der Atemluft.</p>
          <p>Für Ihre individuelle Therapie stehen uns verschiedene Verfahren zur Verfügung, die wir sorgfältig auf Ihre Bedürfnisse abstimmen. Dazu zählen diverse Schröpfmassage-Techniken, Chiropraktik nach Ackermann, Injektionen und Infusionen, Darmsanierung sowie die bewährte Bioresonanz-Therapie.</p>
          <p>Unser Ziel ist es, Ihre Gesundheit nachhaltig zu verbessern und Ihnen zu mehr Wohlbefinden zu verhelfen. Wir laden Sie herzlich ein, sich in unserer Praxis wohlzufühlen und gemeinsam mit uns den Weg zu einem gesünderen Leben zu gehen.</p>
          <p>Zögern Sie nicht, uns bei Fragen zu kontaktieren oder einen Termin zu vereinbaren. Wir freuen uns darauf, Sie persönlich kennenzulernen und Sie auf Ihrem Weg zu mehr Gesundheit zu begleiten!</p>
          <p>Ihre Praxisteam Deipenbrock und Mertler</p>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Display>
            {dispalyItems.map(item => (
              <DisplayItem key={item.title}>
                <div>
                  <Img fluid={findMedia(props.data.headerImages.images, item.image).childImageSharp.fluid}/>
                  <h4>{item.title}</h4>
                  <p>{item.descr}</p>
                </div>
                <Button as={Link} to={item.path}>Mehr erfahren...</Button>
              </DisplayItem>
            ))}
          </Display>
          <Spacer/>
        </section>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "headers"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 220, quality: 95, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
